import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
// Form
import GeneralInfoFormSectionPage from './pages/Form/GeneralInfo';
import CoveragesAndLimits from './pages/Form/CoveragesAndLimits';
import DescriptionOfOperations from './pages/Form/DescriptionOfOperations';
import CommoditiesOperations from './pages/Form/CommoditiesOperations';
import ScheduleOfUnits from './pages/Form/ScheduleOfUnits';
import TrailerTypes from './pages/Form/TrailerTypes';
import DriverInformation from './pages/Form/DriverInformation';
import InsuranceHistoryAndLossExperience from './pages/Form/InsuranceHistoryAndLossExperience';
import MileageAndRevenue from './pages/Form/MileageAndRevenue';
import GeneralLiability from './pages/Form/GeneralLiability';
import PolicyInformationQuestions from './pages/Form/PolicyInformationQuestions';
import PolicyOwnershipAndFraudStatement from './pages/Form/PolicyOwnershipAndFraudStatement';
import Attachments from './pages/Form/Attachments';
// Home
import HomePage from './pages/HomePage';
// 404
import Page404 from './pages/Page404';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/form',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/form/general-information" />, index: true },
        { path: 'general-information', element: <GeneralInfoFormSectionPage /> },
        { path: 'coverges-and-limits', element: <CoveragesAndLimits /> },
        { path: 'description-of-operations', element: <DescriptionOfOperations /> },
        { path: 'commodities-operations', element: <CommoditiesOperations /> },
        { path: 'schedule-of-units', element: <ScheduleOfUnits /> },
        { path: 'trailer-types', element: <TrailerTypes /> },
        { path: 'driver-information', element: <DriverInformation /> },
        { path: 'insurance-history-and-loss-experience', element: <InsuranceHistoryAndLossExperience /> },
        { path: 'mileage-and-revenue', element: <MileageAndRevenue /> },
        { path: 'general-liability', element: <GeneralLiability /> },
        { path: 'policy-information-questions', element: <PolicyInformationQuestions /> },
        { path: 'policy-ownership-and-fraud-statement', element: <PolicyOwnershipAndFraudStatement /> },
        { path: 'attachments', element: <Attachments /> },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
