// yup
import * as yup from 'yup';

// ------------------------------------------------------------------------

const formValidationSchema = yup.object().shape({
  generalInformation: yup.object().shape({
    email: yup
      .string()
      .email('Field must be a valid Email')
      .min(3, 'Min 3 symbols')
      .max(254, 'Max 254 symbols')
      .required('The field is required'),
  }),
});

export default formValidationSchema;
