// mui
import { Grid, Typography } from '@mui/material';
// form
import { useForm } from 'react-hook-form';
// little-state-machine
import { useStateMachine } from 'little-state-machine';
// component
import { FormProvider } from '../../../../components/hook-form';
import FormSectionControls from '../../FormSectionControls';
import CommodityPercentages from './commodityPercentages';
// hooks
import useFormStateUpdateOnChange from '../../../../hooks/useFormStateUpdateOnChange';

// -------------------------------------------------------

const Form = () => {
  // state
  const { state } = useStateMachine();

  // form
  const methods = useForm({
    mode: 'onChange',
    defaultValues: state.form,
  });

  const { watch } = methods;

  useFormStateUpdateOnChange(watch);

  return (
    <FormProvider methods={methods}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant={'subtitle1'} color={'GrayText'}>
            Please identify the commodities transported and percentages
          </Typography>
        </Grid>
        <CommodityPercentages />
      </Grid>

      <FormSectionControls step={'commodities-operations'} />
    </FormProvider>
  );
};

export default Form;
