export default {
  liabilityGeneralLiability: {
    alLimit: null,
    glLimit: null,
    hiredAuto: {
      hiredAuto: false,
      costOfHire: null,
      ifAny: false,
    },
    nonOwnedAuto: {
      nonOwnedAuto: false,
      numberOfEmployees: null,
    },
    umLimit: null,
    uimLimit: null,
    medicalPaymentLimit: null,
    pipLimit: null,
  },
  physicalDamageCargo: {
    comprehensiveAndCollisionDeductible: null,
    trailerInterchange: {
      limitDeductible: null,
      cargoLimitDeductible: null,
    },
    nonOwnedTrailerPhysicalDamage: {
      limitDeductible: null,
      numberOfTrailers: null,
    },
  },
};
