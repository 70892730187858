import PropTypes from 'prop-types';
// @mui
import { Grid } from '@mui/material';
// hook-form
import { useFormContext, useWatch } from 'react-hook-form';
// state
import { useStateMachine } from 'little-state-machine';
// components
import { RHFTextField } from '../../../../../components/hook-form';
import CardSection from '../../../../../components/card-section';

//-------------------------------------------------------------------------------------------------

const MileageAndRevenueFields = ({ index }) => {
  // state
  const { state } = useStateMachine();

  // form
  const { control } = useFormContext();

  const title = useWatch({
    control,
    name: `mileageAndRevenue.list.${index}.type`,
    defaultValue: state.form.mileageAndRevenue.list[index].type,
  });

  return (
    <CardSection title={title}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6}>
          <RHFTextField
            name={`mileageAndRevenue.list.${index}.projected`}
            label={'Projected'}
            // autoFocus={index === 0}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <RHFTextField name={`mileageAndRevenue.list.${index}.currentPolicyPeriod`} label={'Current Policy Period'} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <RHFTextField name={`mileageAndRevenue.list.${index}.firstPriorYear`} label={'1st Prior Year'} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <RHFTextField name={`mileageAndRevenue.list.${index}.secondPriorYear`} label={'2nd Prior Year'} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <RHFTextField name={`mileageAndRevenue.list.${index}.thirdPriorYear`} label={'3rd Prior Year'} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <RHFTextField name={`mileageAndRevenue.list.${index}.fourthPriorYear`} label={'4th Prior Year'} />
        </Grid>
      </Grid>
    </CardSection>
  );
};

MileageAndRevenueFields.propTypes = {
  index: PropTypes.number,
};

export default MileageAndRevenueFields;
