// axios
import axios from 'axios';
// notistack
import { enqueueSnackbar } from 'notistack';
// config
import config from '../config';

// -------------------------------------------------------------

export const createAxiosInstance = ({ baseURL, ...rest }) => {
  const axiosInstance = axios.create({
    baseURL,
    withCredentials: true,
    ...rest,
  });

  // Errors Handling
  axiosInstance.interceptors.response.use(
    (response) => {
      const message = response.response?.data?.msg;

      enqueueSnackbar(message || 'Success!', {
        variant: 'success',
      });
    },
    (error) => {
      if (!error.response) {
        enqueueSnackbar('No connection!', {
          variant: 'error',
        });
      } else {
        const message = error.response?.data?.msg;

        enqueueSnackbar(message || 'Unknown Server Error!', {
          variant: 'error',
        });
      }

      Promise.reject(error);
    }
  );

  return axiosInstance;
};

const baseURL = config.apiAdress;

export const axiosApiInstance = createAxiosInstance({ baseURL });
