// @mui
import { Grid, Stack } from '@mui/material';
// form
import { useFieldArray, useFormContext } from 'react-hook-form';
// state
import { useStateMachine } from 'little-state-machine';
// components
import MileageAndRevenueFields from './MileageAndRevenueFields';

//-------------------------------------------------------------------------------------------------

const MileageAndRevenueList = () => {
  // state
  const { state } = useStateMachine();

  // form
  const { control } = useFormContext();

  const { fields } = useFieldArray({
    control,
    name: 'mileageAndRevenue.list',
    defaultValues: state.form.mileageAndRevenue.list,
  });

  return (
    <Grid item xs={12}>
      <Stack spacing={2}>
        {fields?.map(({ id }, idx) => (
          <MileageAndRevenueFields key={id} index={idx} />
        ))}
      </Stack>
    </Grid>
  );
};

export default MileageAndRevenueList;
