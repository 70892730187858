import { useEffect } from 'react';
// lodash
import { debounce } from 'lodash';
// little-state-machine
import { useStateMachine } from 'little-state-machine';
import { updateForm } from '../store/form/actions';

// -------------------------------------------------------

const useFormStateUpdateOnChange = (watch) => {
  // state actions
  const { actions } = useStateMachine({ updateForm });

  // update state on form change
  useEffect(() => {
    const debouncedUpdateForm = debounce((data) => actions.updateForm(data), 100);

    watch((data) => debouncedUpdateForm(data));

    return () => {
      debouncedUpdateForm.cancel();
    };
  }, [actions, watch]);
};

export default useFormStateUpdateOnChange;
