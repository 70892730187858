import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box, Link, Stack, Card } from '@mui/material';
// components
import Page from '../components/page';
import Logo from '../components/logo/Logo';
import Banner from '../components/banner';
// config
import config from '../config';
// hooks
import useResponsive from '../hooks/useResponsive';

// ----------------------------------------------------------------------

const CoverStyle = styled(Card)(({ theme }) => ({
  width: '50%',
  zIndex: -1,
  maxWidth: 564,
  margin: theme.spacing(2, 0, 2, 2),
  background: `url('/assets/covers/homePage.jpg'), linear-gradient(rgba(0,0,0,0.0),rgba(0,0,0,0.1))`,
  backgroundBlendMode: 'overlay',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
}));

const ContentWrapper = styled(Stack)(({ theme }) => ({
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

const { contactEmail } = config;

export default function HomePage() {
  const lgUp = useResponsive('up', 'lg');
  const smUp = useResponsive('up', 'sm');

  return (
    <Page title={'McGriff Small Fleet Trucking Insurance'}>
      <Box display={'flex'} minHeight={'100vh'}>
        {lgUp && <CoverStyle />}
        <Container maxWidth="md">
          <ContentWrapper spacing={2}>
            {smUp && <Logo width={'100%'} sx={{ maxWidth: '350px', margin: '0 auto' }} />}

            <Banner type={'info'} sx={{ p: { xl: 2 } }}>
              <Typography variant="h3" component={'h1'}>
                McGriff Small Fleet Trucking Insurance
              </Typography>
              <Typography>
                Before starting your application, please have the following documents ready (does not apply to new DOT
                authorities):
              </Typography>
              <Box pl={2}>
                <Typography>IFTAs (if applicable)</Typography>
                <Typography>Loss Runs (past insurance experience, if applicable 3-5 years)</Typography>
                <Typography>Driver List, name, date of birth, etc.</Typography>
                <Typography>Equipment Details – year, make, model, and values</Typography>
              </Box>

              <Typography>
                If you have trouble uploading any requested documents, please email them to{' '}
                <Link href={`mailto:${contactEmail}`}>{contactEmail}</Link>
              </Typography>

              <Typography>
                McGriff will reach out to you after we receive your application and advise you of any information
                needed. We cannot provide a quote for incomplete applications.
              </Typography>
              <Stack direction={'row'} spacing={2} mt={2} alignItems={'center'} justifyContent={'flex-end'}>
                <Button to="/form" size="large" variant="outlined" component={RouterLink}>
                  Apply Now
                </Button>
              </Stack>
            </Banner>
          </ContentWrapper>
        </Container>
      </Box>
    </Page>
  );
}
