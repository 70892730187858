// mui
import { Grid, Stack, Typography } from '@mui/material';
// form
import { useForm } from 'react-hook-form';
// little-state-machine
import { useStateMachine } from 'little-state-machine';
// component
import {
  FormProvider,
  RHFTextField,
  RHFRadioButtons,
  yesNoOptions,
  FieldCondition,
} from '../../../../components/hook-form';
import FormSectionControls from '../../FormSectionControls';
// hooks
import useFormStateUpdateOnChange from '../../../../hooks/useFormStateUpdateOnChange';

// -------------------------------------------------------

const Form = () => {
  // state
  const { state } = useStateMachine();

  // form
  const methods = useForm({
    mode: 'onChange',
    defaultValues: state.form,
  });

  const { watch } = methods;

  useFormStateUpdateOnChange(watch);

  return (
    <FormProvider methods={methods}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <RHFRadioButtons
            name={'policyInformationQuestions.anticipatedUnitsAdditionDuringPolicyTerm'}
            label={'Do you anticipate adding units during the policy term?'}
            options={yesNoOptions}
          />
        </Grid>

        <FieldCondition fieldName="policyInformationQuestions.anticipatedUnitsAdditionDuringPolicyTerm">
          <Grid item xs={12} container>
            <Grid item xs={12} md={6}>
              <Stack spacing={2}>
                <Typography variant={'subtitle1'} color={'GrayText'}>
                  How many units will be added?
                </Typography>

                <RHFTextField
                  type={'number'}
                  name={'policyInformationQuestions.numberOfUnitsToAdd'}
                  label={'Number of units to add'}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={12} md={6}>
              <Stack spacing={2}>
                <Typography variant={'subtitle1'} color={'GrayText'}>
                  How many units will be owned (registered) by named insured?
                </Typography>
                <RHFTextField
                  type={'number'}
                  name={'policyInformationQuestions.numberOwnedUnitsByNamedInsured'}
                  label={'Number of owned units by named insured'}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={12} md={6}>
              <Stack spacing={2}>
                <Typography variant={'subtitle1'} color={'GrayText'}>
                  How many units will be owner-operators working for you?
                </Typography>
                <RHFTextField
                  type={'number'}
                  name={'policyInformationQuestions.numberOfOwnerOperatorUnits'}
                  label={'Number of owner-operators units'}
                />
              </Stack>
            </Grid>
          </Grid>
        </FieldCondition>

        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12} md={6}>
            <RHFRadioButtons
              name={'policyInformationQuestions.hireOtherCarriersOrOwnerOperators'}
              label={'Do you hire other motor carriers or owner-operators to haul for you?'}
              options={yesNoOptions}
            />
          </Grid>

          <FieldCondition fieldName="policyInformationQuestions.hireOtherCarriersOrOwnerOperators">
            <Grid item xs={12} md={6}>
              <RHFRadioButtons
                name={'policyInformationQuestions.hireOtherCarriersOrOwnerOperatorsScheduled'}
                label={'Are these scheduled?'}
                options={yesNoOptions}
              />
            </Grid>
          </FieldCondition>
        </Grid>

        <Grid item xs={12} md={6}>
          <RHFRadioButtons
            name={'policyInformationQuestions.hazardousMaterialHauling'}
            label={'Do you haul any hazardous material, extra hazardous substances, or waste commodities?'}
            options={yesNoOptions}
          />
        </Grid>

        <Grid item xs={12}>
          <RHFRadioButtons
            name={'policyInformationQuestions.gpsEldDashCamMonitoringEquipment'}
            label={'Are any units equipped with GPS/ELD/Dash Cams/Real Time Driver Monitoring?'}
            options={yesNoOptions}
          />
        </Grid>

        <FieldCondition fieldName="policyInformationQuestions.gpsEldDashCamMonitoringEquipment">
          <Grid item xs={12}>
            <Typography variant={'subtitle1'} color={'GrayText'}>
              Please describe the type of device and capabilities in below space and provider
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <RHFTextField
              name={'policyInformationQuestions.gpsEldDashCamMonitoringEquipmentDescription'}
              label={'Description'}
              multiline
              minRows={5}
            />
          </Grid>
        </FieldCondition>

        <Grid item xs={12} md={6}>
          <RHFRadioButtons
            name={'policyInformationQuestions.pullDoubleTrailers'}
            label={'Do you pull double trailers? If yes, please describe customary and usual routes in below'}
            options={yesNoOptions}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <RHFRadioButtons
            name={'policyInformationQuestions.pullTripleTrailers'}
            label={'Do you pull triple trailers?'}
            options={yesNoOptions}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <RHFRadioButtons
            name={'policyInformationQuestions.oversizeOrOverweightLoads'}
            label={'Do you haul oversize or overweight loads?'}
            options={yesNoOptions}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <RHFRadioButtons
            name={'policyInformationQuestions.tightDeliveryTimeConstraints'}
            label={'Do you haul commodities that are subject to tight delivery time constraints?'}
            options={yesNoOptions}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <RHFRadioButtons
            name={'policyInformationQuestions.isEquipmentScheduledOnApplication'}
            label={'Is all the equipment operation under your authority scheduled on this application?'}
            options={yesNoOptions}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <RHFRadioButtons
            name={'policyInformationQuestions.isInsuredInvolvedInOtherBusinessActivity'}
            label={'Is the insured involved in any business activity other than trucking?'}
            options={yesNoOptions}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <RHFRadioButtons
            name={'policyInformationQuestions.actAsFreightBrokerOrForwarderOrLoadArranger'}
            label={'Do you act as a freight-broker or freight-forwarder or arrange loads for others?'}
            options={yesNoOptions}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <RHFRadioButtons
            name={'policyInformationQuestions.everFiledBankruptcy'}
            label={'Have you or any business you owned ever filed for bankruptcy?'}
            options={yesNoOptions}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <RHFRadioButtons
            name={'policyInformationQuestions.declinedCancelledNonRenewedLast3Years'}
            label={'Any policy or coverage declined, cancelled, non-renewed during the prior 3 years?'}
            options={yesNoOptions}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <RHFRadioButtons
            name={'policyInformationQuestions.truckerUniformIntermodalEndorsementRequired'}
            label={'Is a Truckers Uniform Intermodal Interchange endorsement required?'}
            options={yesNoOptions}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <RHFRadioButtons
            name={'policyInformationQuestions.carryWorkersCompensation'}
            label={"Do you carry Worker's Compensation?"}
            options={yesNoOptions}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <RHFRadioButtons
            name={'policyInformationQuestions.isAnyOperationSeasonal'}
            label={'Is any portion of your operation seasonal? If yes, explain below'}
            options={yesNoOptions}
          />
        </Grid>

        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12} md={6}>
            <RHFRadioButtons
              name={'policyInformationQuestions.leaseToOthers'}
              label={'Do you lease your vehicles to others?'}
              options={yesNoOptions}
            />
          </Grid>

          <FieldCondition fieldName={'policyInformationQuestions.leaseToOthers'}>
            <Grid item xs={12} md={6}>
              <RHFRadioButtons
                name={'policyInformationQuestions.primaryLiabilityCoverageProvider'}
                label={'Who must provide primary liability coverage?'}
                options={[
                  { value: 'You', label: 'You' },
                  { value: 'Lessee', label: 'Lessee' },
                ]}
              />
            </Grid>
          </FieldCondition>
        </Grid>

        <Grid item xs={12} md={6}>
          <RHFRadioButtons
            name={'policyInformationQuestions.formalSafetyProgram'}
            label={'Do you have a formal safety program in place?'}
            options={yesNoOptions}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <RHFRadioButtons
            name={'policyInformationQuestions.vehicleMaintenanceProgramInPlace'}
            label={'Do you have a vehicle maintenance program in place?'}
            options={yesNoOptions}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <RHFRadioButtons
            name={'policyInformationQuestions.personalVehicleUse'}
            label={'Is there personal use of vehicles?'}
            options={yesNoOptions}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <RHFRadioButtons
            name={'policyInformationQuestions.equipmentScheduledOnApplication'}
            label={'Is all the equipment operating under your authority scheduled on this application?'}
            options={yesNoOptions}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <RHFRadioButtons
            name={'policyInformationQuestions.anticipateTravelOutsideUS'}
            label={'Do you anticipate traveling to outside of the U.S. (Canada or Mexico) during the policy term?'}
            options={yesNoOptions}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <RHFRadioButtons
            name={'policyInformationQuestions.forwardFacingCameras'}
            label={'Do you have forward facing cameras in your units?'}
            options={yesNoOptions}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant={'subtitle1'} color={'GrayText'}>
            Please provide additional explanation for any answers needed
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <RHFTextField
            name={'policyInformationQuestions.additionalExplanation'}
            label={'Additional explanation'}
            multiline
            minRows={5}
          />
        </Grid>
      </Grid>

      <FormSectionControls step={'policy-information-questions'} />
    </FormProvider>
  );
};

export default Form;
