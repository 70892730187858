import PropTypes from 'prop-types';
// mui
import { ListItem, Typography } from '@mui/material';

// ------------------------------------------------------------

const AttachmentsInfoBannerListItem = ({ children }) => (
  <ListItem>
    <Typography color="text.secondary">{children}</Typography>
  </ListItem>
);

AttachmentsInfoBannerListItem.propTypes = {
  children: PropTypes.string.isRequired,
};

export default AttachmentsInfoBannerListItem;
