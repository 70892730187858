// @mui
import { Container, Stack, Typography } from '@mui/material';
// components
import Page from '../../components/page';
import Uploader from '../../sections/form/attachments/Uploader';
import AttachmentsInfoBanner from '../../sections/form/attachments/AttachmentsInfoBanner';
import FormSectionControls from '../../sections/form/FormSectionControls';

// ----------------------------------------------------------------------

const Attachments = () => (
  <Page title={'Attachments'}>
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
        <Typography variant="h4" gutterBottom>
          Attachments
        </Typography>
      </Stack>

      <Stack spacing={2}>
        <AttachmentsInfoBanner />
        <Uploader />
        <FormSectionControls step={'attachments'} hideDivider />
      </Stack>
    </Container>
  </Page>
);

export default Attachments;
