// router
import { Link as RouterLink } from 'react-router-dom';
// mui
import { Grid, Typography, Link } from '@mui/material';
// form
import {  useForm } from 'react-hook-form';
// little-state-machine
import { useStateMachine } from 'little-state-machine';
// component
import { FormProvider } from '../../../../components/hook-form';
import FormSectionControls from '../../FormSectionControls';
import ScheduleOfUnitsList from './scheduleOfUnitsList';
// hooks
import useFormStateUpdateOnChange from '../../../../hooks/useFormStateUpdateOnChange';

// -------------------------------------------------------

const Form = () => {
  // state
  const { state } = useStateMachine();

  // form
  const methods = useForm({
    mode: 'onChange',
    defaultValues: state.form,
  });

  const { watch } = methods;

  useFormStateUpdateOnChange(watch);

  return (
    <FormProvider methods={methods}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant={'subtitle1'} color={'GrayText'}>
            * You can skip this section and add a spreadsheet of the schedule of units in the{' '}
            <Link component={RouterLink} to="/form/attachments" underline="none">
              Attachment section
            </Link>
          </Typography>
        </Grid>
        <ScheduleOfUnitsList />
      </Grid>

      <FormSectionControls step={'schedule-of-units'} />
    </FormProvider>
  );
};

export default Form;
