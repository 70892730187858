import PropTypes from 'prop-types';
// @mui
import { Grid } from '@mui/material';
// components
import { RHFTextField } from '../../../../../components/hook-form';
import CardSection from '../../../../../components/card-section';

//-------------------------------------------------------------------------------------------------

const OperationStateDataFields = ({ onRemove, isRemoveDisabled, index }) => (
  <CardSection title={`State #${index + 1}`} onRemove={() => onRemove(index)} isRemoveDisabled={isRemoveDisabled}>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <RHFTextField name={`descriptionOfOperations.operationStatesData[${index}].state`} label={'State'} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <RHFTextField
          type={'number'}
          name={`descriptionOfOperations.operationStatesData[${index}].percentage`}
          label={'Percentage'}
        />
      </Grid>
    </Grid>
  </CardSection>
);

OperationStateDataFields.propTypes = {
  onRemove: PropTypes.func,
  isRemoveDisabled: PropTypes.bool,
  index: PropTypes.number,
};

export default OperationStateDataFields;
