import PropTypes from 'prop-types';
// @mui
import { IconButton, InputAdornment } from '@mui/material';
// components
import { RHFTextField } from '../../../../../components/hook-form';
import Iconify from '../../../../../components/iconify/Iconify';

//-------------------------------------------------------------------------------------------------

const OtherTypeField = ({ index, onRemove }) => (
  <RHFTextField
    name={`trailerTypes.other.${index}.name`}
    label={`Type #${index + 1}`}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <IconButton color={'error'} onClick={() => onRemove(index)} edge="end">
            <Iconify icon={'carbon:trash-can'} width={25} height={25} />
          </IconButton>
        </InputAdornment>
      ),
    }}
  />
);

OtherTypeField.propTypes = {
  onRemove: PropTypes.func,
  index: PropTypes.number,
};

export default OtherTypeField;
