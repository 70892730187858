export const defaultFacilityObject = {
  occupancyType: 'None',
  address: null,
  numberOfUnits: null,
  numberOfEmployees: null,
  isFenced: false,
  isLighted: false,
  hasSecurityGuard: false,
  isPublicAccessAllowed: false,
  hasSecurityCamera: false,
  nonTruckingPayroll: null,
};

export const defaultWaiverOfSubrogationOrAdditionalInsuredObject = {
  name: null,
  address: null,
};

const questionnaire = {
  fuelStorage: {
    hasOnPremisesFuelStorage: null,
    isFuelStorageBelowGround: null,
  },
  warehousingServices: {
    provideWarehousingServices: null,
    payroll: null,
    description: null,
  },
  thirdPartyLogisticsServices: {
    provideThirdPartyLogisticsServices: null,
    payroll: null,
    description: null,
  },
};

export default {
  ...questionnaire,
  facilities: [defaultFacilityObject],
  waiverOfSubrogationOrAdditionalInsured: [defaultWaiverOfSubrogationOrAdditionalInsuredObject],
};
