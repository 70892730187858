import { useState, useEffect } from 'react';
// uppy
import Uppy from '@uppy/core';
import GoldenRetriever from '@uppy/golden-retriever';
import Compressor from '@uppy/compressor';

// -----------------------------------------------------------------------

// uppy
const uppy = new Uppy({
  restrictions: {
    maxFileSize: 20480000,
    maxNumberOfFiles: 20,
    minNumberOfFiles: 0,
    allowedFileTypes: ['image/*', '.csv', '.xlsx', '.pdf'],
  },
})
  // serviceWorker: true disables preview but can work with large files
  .use(GoldenRetriever)
  .use(Compressor);

const fileListeners = ['file-added', 'file-removed', 'restored'];

const useUppy = () => {
  const [files, setFiles] = useState(null);

  const onUpdateFiles = () => {
    setFiles(uppy.getFiles());
  };

  fileListeners.map((event) => uppy.on(event, onUpdateFiles));

  useEffect(onUpdateFiles, []);

  return { uppy, files };
};

export default useUppy;
