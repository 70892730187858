import defaultState, { FORM_STATE_VERSION, FORM_STATE_VERSION_KEY } from './state';

// ---------------------------------------------------------------

export const updateForm = (state, payload) => ({
  ...state,
  form: {
    ...state.form,
    ...payload,
  },
});

export const clearForm = (state) => ({
  ...state,
  form: defaultState,
});

export const updateFormStateVersion = (state) => {
  const storedVersion = Number(localStorage.getItem(FORM_STATE_VERSION_KEY));

  if (storedVersion !== FORM_STATE_VERSION) {
    localStorage.setItem(FORM_STATE_VERSION_KEY, `${FORM_STATE_VERSION}`);
    return clearForm(state);
  }

  return state;
};
