// @mui
import { Container, Stack, Typography } from '@mui/material';
// components
import Page from '../../components/page';
import Form from '../../sections/form/insuranceHistoryAndLossExperience/form';

// ----------------------------------------------------------------------

const InsuranceHistoryAndLossExperience = () => (
  <Page title={'Insurance History & Loss Experience'}>
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
        <Typography variant="h4" gutterBottom>
          Insurance History & Loss Experience
        </Typography>
      </Stack>

      <Form />
    </Container>
  </Page>
);

export default InsuranceHistoryAndLossExperience;
