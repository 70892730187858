// mui
import { Grid, Typography } from '@mui/material';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// little-state-machine
import { useStateMachine } from 'little-state-machine';
// component
import { FormProvider, RHFTextField } from '../../../../components/hook-form';
import FormSectionControls from '../../FormSectionControls';
// hooks
import useFormStateUpdateOnChange from '../../../../hooks/useFormStateUpdateOnChange';
// services
import formValidationSchema from '../../../../services/validation/formValidationSchema';

// -------------------------------------------------------

const Form = () => {
  // state
  const { state } = useStateMachine();

  // form
  const methods = useForm({
    resolver: yupResolver(formValidationSchema),
    defaultValues: state.form,
  });

  const { watch, trigger } = methods;

  useFormStateUpdateOnChange(watch);

  const validate = async () => {
    const isValid = await trigger('generalInformation.email');

    return isValid;
  };

  return (
    <FormProvider methods={methods}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>
            ALL VEHICLES SCHEDULED ON THE POLICY MUST BE OWNED/REGISTERED TO THE NAMED INSURED OR HAVE A WRITTEN LEASE
            AGREEMENT BETWEEN THE INSURED AND VEHICLE OWNER. ANY PERSON WHO KNOWINGLY AND WITH INTENT TO DEFRAUD ANY
            INSURANCE COMPANY OR ANOTHER PERSON FILES AN APPLICATION FOR INSURANCE OR STATEMENT OF CLAIM CONTAINING ANY
            MATERIALLY FALSE INFORMATION, OR CONCEALS FOR THE PURPOSE OF MISLEADING INFORMATION CONCERNING ANYFACT
            MATERIAL THERETO, COMMITS A FRAUDULENT INSURANCE ACT, WHICH IS A CRIME AND SUBJECTS THE PERSON TO CRIMINAL
            AND [NY: SUBSTANTIAL] CIVILPENALTIES. (NOT APPLICABLE IN CO, FL, HI, MA, NE, OH, OK, OR, or VT; in DC, LA,
            ME, TN, VA AND WA, INSURANCE BENEFITS MAY ALSO BE DENIED)
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography>
            IN FLORIDA, ANY PERSON WHO KNOWINGLY AND WITH INTENT TO INJURE, DEFRAUD, OR DECEIVE ANY INSURER FILES A
            STATEMENT OF CLAIM OR ANAPPLICATION CONTAINING ANY FALSE, INCOMPLETE, OR MISLEADING INFORMATION IS GUILTY OF
            A FELONY OF THE THIRD DEGREE. THE UNDERSIGNED IS AN AUTHORIZED REPRESENTATIVE OF THE APPLICANT AND
            REPRESENTS THAT REASONABLE ENQUIRY HAS BEEN MADE TO OBTAINTHE ANSWERS TO QUESTIONS ON THIS APPLICATION.
            HE/SHE REPRESENTS THAT THE ANSWERS ARE TRUE, CORRECT AND COMPLETE TO THE BEST OF HIS/HER KNOWLEDGE.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography>
            I AUTHORIZE TO OBTAIN A COPY OF MY MOTOR VEHICLE RECORD FOR RATING/UNDERWRITING THE INSURANCE FOR WHICH I
            HAVE APPLIED. I ALSO UNDERSTAND THAT A ROUTINE INQUIRY MAY BE MADE PROVIDING INFORMATION CONCERNING MY
            CHARACTER, GENERAL REPUTATION, PERSONAL CHARACTERISTICS AND MODE OF LIVING, AS WELL AS ANY PERTINENT
            FINANCIAL DATA DEEMED NECESSARY. UPON WRITTEN REQUEST, INFORMATION AS TO THE NATURE AND SCOPE OF THE REPORT
            WILL BE PROVIDED TO ME.
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={3} mt={1}>
          <RHFTextField name={'policyOwnershipAndFraudStatement.applicantName'} label={'Applicant Name'} />
        </Grid>
        <Grid item xs={12} sm={6} md={3} mt={1}>
          <RHFTextField name={'generalInformation.email'} label={'Email'} required />
        </Grid>

        <Grid item xs={12} sm={12} md={6} mt={1} container alignItems={'center'} justifyContent={'flex-end'}>
          <Typography>McGriff CA License #0C64544</Typography>
        </Grid>
      </Grid>

      <FormSectionControls step={'policy-ownership-and-fraud-statement'} validate={validate} />
    </FormProvider>
  );
};

export default Form;
