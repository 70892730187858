import PropTypes from 'prop-types';
// form
import { FormProvider as Form } from 'react-hook-form';

// ----------------------------------------------------------------------

FormProvider.propTypes = {
  children: PropTypes.node.isRequired,
  methods: PropTypes.object.isRequired,
  style: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default function FormProvider({ children, onSubmit, methods, style }) {
  return (
    <Form {...methods}>
      <form onSubmit={onSubmit} style={style} noValidate>
        {children}
      </form>
    </Form>
  );
}
