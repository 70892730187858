import { axiosApiInstance } from './axios';

// -------------------------------------------------------------

const apiRoot = '/v1';

const formApi = {
  upload: async ({ data, files }) => {
    const formData = new FormData();
    formData.append('data', JSON.stringify(data));

    files?.forEach((file) => {
      formData.append('files', file.data, file.name);
    });

    const res = await axiosApiInstance.post(`${apiRoot}/form`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return res;
  },
};

export default formApi;
