// router
import { Link as RouterLink } from 'react-router-dom';
// mui
import { Button, Grid, Stack, Typography, Link } from '@mui/material';
// form
import { useFieldArray, useForm } from 'react-hook-form';
// little-state-machine
import { useStateMachine } from 'little-state-machine';
import { defaultDriverInformationObject } from '../../../../store/form/state/driverInformation';
// component
import { FormProvider } from '../../../../components/hook-form';
import FormSectionControls from '../../FormSectionControls';
import Iconify from '../../../../components/iconify/Iconify';
// hooks
import useFormStateUpdateOnChange from '../../../../hooks/useFormStateUpdateOnChange';
import DriverFields from './fields/DriverFields';

// -------------------------------------------------------

const Form = () => {
  // state
  const { state } = useStateMachine();

  // form
  const methods = useForm({
    mode: 'onChange',
    defaultValues: state.form,
  });

  const { watch, control } = methods;

  useFormStateUpdateOnChange(watch);

  const {
    fields: drivers,
    append: appendDriver,
    remove: removeDriver,
  } = useFieldArray({
    control,
    name: 'driverInformation',
    defaultValues: state.form.driverInformation,
  });

  const handleAppendDriver = () => appendDriver(defaultDriverInformationObject);
  const handleRemoveDriver = (index) => (drivers?.length > 1 ? removeDriver(index) : null);

  return (
    <FormProvider methods={methods}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant={'subtitle1'} color={'GrayText'}>
            * You can skip this section and add a spreadsheet of drivers in the{' '}
            <Link component={RouterLink} to="/form/attachments" underline="none">
              Attachment section
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={2}>
            {drivers?.map(({ id }, idx) => (
              <DriverFields
                onRemove={handleRemoveDriver}
                isRemoveDisabled={drivers?.length === 1}
                index={idx}
                key={id}
              />
            ))}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack alignItems={'flex-end'} direction={'column'}>
            <Button
              variant={'text'}
              onClick={handleAppendDriver}
              endIcon={<Iconify icon="eva:plus-fill" width={25} height={25} />}
            >
              Add Driver
            </Button>
          </Stack>
        </Grid>
      </Grid>

      <FormSectionControls step={'driver-information'} />
    </FormProvider>
  );
};

export default Form;
