import PropTypes from 'prop-types';
// form
import { Controller, useFormContext } from 'react-hook-form';
// mui
import { DatePicker } from '@mui/x-date-pickers';

// ----------------------------------------------------------------------

RHFDatePicker.propTypes = {
  name: PropTypes.string,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  readOnly: PropTypes.bool,
  helperText: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  required: PropTypes.bool,
};

RHFDatePicker.defaultProps = {
  fullWidth: true,
};

export function RHFDatePicker({ name, label, helperText, defaultValue, readOnly, required, fullWidth, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      defaultValue={defaultValue || control._defaultValues[name] || ''}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <DatePicker
          {...field}
          value={field.value ? new Date(field.value) : null}
          format="MM-dd-yyyy"
          orientation="portrait"
          label={label}
          helperText={error?.message || helperText}
          inputProps={{ readOnly }}
          required={required}
          error={!!error}
          {...other}
          sx={{ ...(fullWidth && { width: '100%' }), ...other.sx }}
        />
      )}
    />
  );
}