// @mui
import { Button, Divider, Grid, Stack } from '@mui/material';
// form
import { useFieldArray, useFormContext } from 'react-hook-form';
// state
import { useStateMachine } from 'little-state-machine';
// components
import OtherTypeField from './ScheduleOfUnitsFields';
import Iconify from '../../../../../components/iconify/Iconify';

//-------------------------------------------------------------------------------------------------

const OtherTypesList = () => {
  // state
  const { state } = useStateMachine();

  // form
  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'trailerTypes.other',
    defaultValues: state.form.trailerTypes.other,
  });

  const onAdd = () => append({ name: '', value: true });
  const onRemove = (index) => remove(index);

  return (
    <>
      {fields.length > 0 && (
        <Grid item xs={12}>
          <Divider sx={{ my: 2 }} />
        </Grid>
      )}

      {fields?.map(({ id }, idx) => (
        <Grid item key={id} xs={12} sm={6} md={4} lg={3}>
          <OtherTypeField onRemove={onRemove} index={idx} />
        </Grid>
      ))}

      <Grid item xs={12}>
        <Stack alignItems={'flex-end'} direction={'column'}>
          <Button variant={'text'} onClick={onAdd} endIcon={<Iconify icon="eva:plus-fill" width={25} height={25} />}>
            Add Trailer Type
          </Button>
        </Stack>
      </Grid>
    </>
  );
};

export default OtherTypesList;
