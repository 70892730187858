import PropTypes from 'prop-types';
// @mui
import { Grid } from '@mui/material';
// components
import { RHFTextField } from '../../../../../components/hook-form';
import CardSection from '../../../../../components/card-section';

//-------------------------------------------------------------------------------------------------

const InsuranceHistoryAndLossExperienceFields = ({ index, onRemove, isRemoveDisabled }) => (
  <CardSection
    title={`Claims History and Loss Experience #${index + 1}`}
    onRemove={() => onRemove(index)}
    isRemoveDisabled={isRemoveDisabled}
  >
    <Grid container spacing={2}>
      <Grid item xs={12} sm={3}>
        <RHFTextField name={`insuranceHistoryAndLossExperience.list.${index}.company`} label={'Company'} />
      </Grid>
      <Grid item xs={12} sm={3}>
        <RHFTextField name={`insuranceHistoryAndLossExperience.list.${index}.policyTerm`} label={'Policy Term'} />
      </Grid>
      <Grid item xs={12} sm={3}>
        <RHFTextField
          type={'number'}
          name={`insuranceHistoryAndLossExperience.list.${index}.numberOfPowerUnits`}
          label={'# of Power Units'}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <RHFTextField
          type={'number'}
          name={`insuranceHistoryAndLossExperience.list.${index}.numberOfTrailers`}
          label={'# of Trailers'}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <RHFTextField
          name={`insuranceHistoryAndLossExperience.list.${index}.premium`}
          label={'Premium'}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <RHFTextField
          name={`insuranceHistoryAndLossExperience.list.${index}.incurredClaimsAutoLiability`}
          label={'Incurred Claims Auto Liability'}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <RHFTextField
          name={`insuranceHistoryAndLossExperience.list.${index}.incurredClaimsPhysDmg`}
          label={'Incurred Claims Phys Dmg'}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <RHFTextField
          type={'number'}
          name={`insuranceHistoryAndLossExperience.list.${index}.numberOfClaims`}
          label={'# of Claims'}
        />
      </Grid>
    </Grid>
  </CardSection>
);

InsuranceHistoryAndLossExperienceFields.propTypes = {
  onRemove: PropTypes.func,
  isRemoveDisabled: PropTypes.bool,
  index: PropTypes.number,
};

export default InsuranceHistoryAndLossExperienceFields;
