// @mui
import { Button, Grid, Stack, Typography } from '@mui/material';
// form
import { useFieldArray, useFormContext } from 'react-hook-form';
// state
import { useStateMachine } from 'little-state-machine';
import { defaultWaiverOfSubrogationOrAdditionalInsuredObject } from '../../../../../store/form/state/generalLiability';
// components
import WaiverOfSubrogationOrAdditionalInsuredFields from './WaiverOfSubrogationOrAdditionalInsuredFields';
import Iconify from '../../../../../components/iconify/Iconify';

//-------------------------------------------------------------------------------------------------

const WaiverOfSubrogationOrAdditionalInsuredList = () => {
  // state
  const { state } = useStateMachine();

  // form
  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'generalLiability.waiverOfSubrogationOrAdditionalInsured',
    defaultValues: state.form.generalLiability.waiverOfSubrogationOrAdditionalInsured,
  });

  const isRemoveDisabled = fields?.length < 2;

  const onAdd = () => append(defaultWaiverOfSubrogationOrAdditionalInsuredObject);
  const onRemove = (index) => (isRemoveDisabled ? null : remove(index));

  return (
    <>
      <Grid item xs={12}>
        <Typography variant={'subtitle1'} color={'GrayText'}>
          If Waiver of Subrogation or Additional Insured needed, please provide name, address
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Stack spacing={2}>
          {fields?.map(({ id }, idx) => (
            <WaiverOfSubrogationOrAdditionalInsuredFields key={id} index={idx} onRemove={onRemove} isRemoveDisabled={isRemoveDisabled} />
          ))}
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack alignItems={'flex-end'} direction={'column'}>
          <Button variant={'text'} onClick={onAdd} endIcon={<Iconify icon="eva:plus-fill" width={25} height={25} />}>
            Add Additional Insured / Waiver of Subrogation
          </Button>
        </Stack>
      </Grid>
    </>
  );
};

export default WaiverOfSubrogationOrAdditionalInsuredList;
