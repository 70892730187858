import PropTypes from 'prop-types';
// @mui
import { Grid } from '@mui/material';
// components
import { RHFDatePicker, RHFSelect, RHFTextField } from '../../../../../components/hook-form';
import CardSection from '../../../../../components/card-section';

//-------------------------------------------------------------------------------------------------

const DriverFields = ({ onRemove, isRemoveDisabled, index }) => (
  <CardSection title={`Driver #${index + 1}`} onRemove={() => onRemove(index)} isRemoveDisabled={isRemoveDisabled}>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4}>
        <RHFTextField
          name={`driverInformation[${index}].name`}
          label={'Driver Name'}
          // autoFocus={index === 0}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <RHFDatePicker name={`driverInformation[${index}].dateOfBirth`} label={'Date Of Birth'} />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <RHFTextField name={`driverInformation[${index}].license`} label={'License #'} />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <RHFTextField name={`driverInformation[${index}].state`} label={'State'} />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <RHFTextField
          type={'number'}
          name={`driverInformation[${index}].yrsOfCDLExperience`}
          label={'Yrs of CDL Experience'}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <RHFTextField type={'number'} name={`driverInformation[${index}].yrsWithCompany`} label={'Yrs with Company'} />
      </Grid>
      <Grid item xs={12} md={3}>
        <RHFSelect
          name={`driverInformation[${index}].typeOfEmployee`}
          label={'Type Of Employee '}
          options={['Named Insured', 'Employee', 'Owner Operator', 'None']}
        />
      </Grid>
    </Grid>
  </CardSection>
);

DriverFields.propTypes = {
  onRemove: PropTypes.func,
  isRemoveDisabled: PropTypes.bool,
  index: PropTypes.number,
};

export default DriverFields;
