import { Grid, Divider } from '@mui/material';

// --------------------------------------------------------------

const FormDivider = () => (
  <Grid item xs={12}>
    <Divider />
  </Grid>
);

export default FormDivider;
