export const defaultOperationStatesDataObject = {
  state: null,
  percentage: null,
};

export default {
  typeOfOperation: 'None',
  typeOfOperationOther: null,
  rangeOfTransport: 'None',
  percentOfLoadsByRadius: {
    within250mi: null,
    within251to750mi: null,
    within751to1500mi: null,
    over1500mi: null,
  },
  averageRadius: null,
  operationStatesData: [defaultOperationStatesDataObject],
};
