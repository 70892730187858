// components
import { StyledDashboard } from './styles';
// hooks
import useUppy from '../../../../hooks/useUppy';

// ----------------------------------------------------------------------

export default function FileUploader(props) {
  const { uppy } = useUppy();

  return (
    <StyledDashboard
      showNativePhotoCameraButton
      disableStatusBar
      uppy={uppy}
      proudlyDisplayPoweredByUppy={false}
      {...props}
    />
  );
}
