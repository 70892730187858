import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';

// ----------------------------------------------------------------------

export const yesNoOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

const RHFRadioButtons = ({ name, options, label, ...other }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControl>
          <FormLabel>{label}</FormLabel>
          <RadioGroup {...field} row {...other}>
            {options.map(({ value, label }) => (
              <FormControlLabel key={value} value={value} control={<Radio />} label={label} />
            ))}
          </RadioGroup>
        </FormControl>
      )}
    />
  );
};

RHFRadioButtons.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
};

export default RHFRadioButtons;
