// mui
import { Link, List, Typography } from '@mui/material';
// components
import Banner from '../../../../components/banner';
import ListItem from './AttachmentsInfoBannerListItem';
// config
import config from '../../../../config';

// ------------------------------------------------------------

const { contactEmail } = config;

const AttachmentsInfoBanner = () => (
  <>
    <Banner type={'info'}>
      <Typography>Please upload the following documents (does not apply to new DOT authorities):</Typography>
      <List dense disablePadding>
        <ListItem>IFTAs (if applicable)</ListItem>
        <ListItem>Loss Runs (past insurance experience, if applicable 3-5 years)</ListItem>
        <ListItem>Driver List, name, date of birth, etc.</ListItem>
        <ListItem>Equipment Details – year, make, model, and values</ListItem>
      </List>
    </Banner>
    <Banner type={'info'}>
      <Typography>
        If you have trouble uploading any requested documents, please email them to{' '}
        <Link href={`mailto:${contactEmail}`}>{contactEmail}</Link>
      </Typography>
    </Banner>
  </>
);

export default AttachmentsInfoBanner;
