// @mui
import { Container, Stack, Typography } from '@mui/material';
// components
import Page from '../../components/page';
import Form from '../../sections/form/mileageAndRevenue/form';

// ----------------------------------------------------------------------

const MileageAndRevenue = () => (
  <Page title={'Mileage & Revenue'}>
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
        <Typography variant="h4" gutterBottom>
          Mileage & Revenue
        </Typography>
      </Stack>

      <Form />
    </Container>
  </Page>
);

export default MileageAndRevenue;
