// mui
import { Grid, Typography } from '@mui/material';
// form
import { FieldCondition, RHFRadioButtons, RHFTextField, yesNoOptions } from '../../../../../components/hook-form';

// -------------------------------------------------------------------------------------------------------------------

const Questionnaire = () => (
  <>
    {/* Fuel Storage */}
    <Grid item xs={12}>
      <Typography variant={'subtitle1'} color={'GrayText'}>
        On-premises Fuel Storage
      </Typography>
    </Grid>
    <Grid item xs={12} md={6}>
      <RHFRadioButtons
        label={'Does the applicant have on-premises fuel storage?'}
        name={'generalLiability.fuelStorage.hasOnPremisesFuelStorage'}
        options={yesNoOptions}
      />
    </Grid>

    <FieldCondition fieldName={'generalLiability.fuelStorage.hasOnPremisesFuelStorage'}>
      <Grid item xs={12} md={6}>
        <RHFRadioButtons
          label={'Is storage below ground?'}
          name={'generalLiability.fuelStorage.isFuelStorageBelowGround'}
          options={yesNoOptions}
        />
      </Grid>
    </FieldCondition>

    {/* Warehousing Services */}
    <Grid item xs={12}>
      <Typography variant={'subtitle1'} color={'GrayText'}>
        Warehousing Services
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <RHFRadioButtons
        label={'Do you provide warehousing services?'}
        name={'generalLiability.warehousingServices.provideWarehousingServices'}
        options={yesNoOptions}
      />
    </Grid>

    <FieldCondition fieldName={'generalLiability.warehousingServices.provideWarehousingServices'}>
      <Grid item xs={12}>
        <RHFTextField label={'Payroll'} name={'generalLiability.warehousingServices.payroll'} />
      </Grid>
      <Grid item xs={12}>
        <RHFTextField
          multiline
          minRows={5}
          label={'Description'}
          name={'generalLiability.warehousingServices.description'}
        />
      </Grid>
    </FieldCondition>

    {/* Third Party Logistics Services */}
    <Grid item xs={12}>
      <Typography variant={'subtitle1'} color={'GrayText'}>
        Third Party Logistics Services
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <RHFRadioButtons
        label={'Do you provide third party logistics services?'}
        name={'generalLiability.thirdPartyLogisticsServices.provideThirdPartyLogisticsServices'}
        options={yesNoOptions}
      />
    </Grid>

    <FieldCondition fieldName={'generalLiability.thirdPartyLogisticsServices.provideThirdPartyLogisticsServices'}>
      <Grid item xs={12}>
        <RHFTextField label={'Payroll'} name={'generalLiability.thirdPartyLogisticsServices.payroll'} />
      </Grid>
      <Grid item xs={12}>
        <RHFTextField
          multiline
          minRows={5}
          label={'Description'}
          name={'generalLiability.thirdPartyLogisticsServices.description'}
        />
      </Grid>
    </FieldCondition>
  </>
);

export default Questionnaire;
