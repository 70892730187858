// @mui
import { Container, Stack, Typography } from '@mui/material';
// components
import Page from '../../components/page';
import Form from '../../sections/form/generalLiability/form';

// ----------------------------------------------------------------------

const GeneralLiability = () => (
  <Page title={'General Liability'}>
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
        <Typography variant="h4" gutterBottom>
          General Liability
        </Typography>
      </Stack>

      <Form />
    </Container>
  </Page>
);

export default GeneralLiability;
