import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// date pickers
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// notistack
import { SnackbarProvider } from 'notistack';
// little-state-machine
import { StateMachineProvider, createStore } from 'little-state-machine';
import state from './store';
import StateMachineSetup from './store/StateMachineSetup';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/scroll-to-top';
// simplebar styles
import 'simplebar-react/dist/simplebar.min.css';
// uppy styles
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';

// ----------------------------------------------------------------------

export const store = createStore(state, { storageType: localStorage });

// ----------------------------------------------------------------------

export default function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SnackbarProvider maxSnack={3}>
              <ScrollToTop />
              <StateMachineProvider>
                <StateMachineSetup />
                <Router />
              </StateMachineProvider>
            </SnackbarProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}
