import generalInformation from './generalInformation';
import coveragesAndLimits from './coveragesAndLimits';
import descriptionOfOperations from './descriptionOfOperations';
import commoditiesOperations from './commoditiesOperations';
import scheduleOfUnits from './scheduleOfUnits';
import trailerTypes from './trailerTypes';
import driverInformation from './driverInformation';
import insuranceHistoryAndLossExperience from './insuranceHistoryAndLossExperience';
import mileageAndRevenue from './mileageAndRevenue';
import generalLiability from './generalLiability';
import policyInformationQuestions from './policyInformationQuestions';
import policyOwnershipAndFraudStatement from './policyOwnershipAndFraudStatement';
// config
import config from '../../../config';

// -----------------------------------------------------

export const FORM_STATE_VERSION = config.formStateVersion;
export const FORM_STATE_VERSION_KEY = 'form-state-version';

export default {
  generalInformation,
  coveragesAndLimits,
  descriptionOfOperations,
  commoditiesOperations,
  scheduleOfUnits,
  trailerTypes,
  driverInformation,
  insuranceHistoryAndLossExperience,
  mileageAndRevenue,
  generalLiability,
  policyInformationQuestions,
  policyOwnershipAndFraudStatement,
};
