import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
// @mui
import { Box, CircularProgress } from '@mui/material';

// ----------------------------------------------------------------------

const Page = ({ children, title = '', meta, isLoading, ...other }) => (
  <>
    <Helmet>
      <title>{title}</title>
      {meta}
    </Helmet>

    {isLoading ? (
      <Box sx={{ width: '100%', height: '100%' }} display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <CircularProgress disableShrink />
      </Box>
    ) : (
      <Box {...other}>{children}</Box>
    )}
  </>
);

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  meta: PropTypes.node,
  isLoading: PropTypes.bool,
};

export default Page;
