const mileageOrRevenueTypes = ['Number of Revenue Units', 'Gross Receipts', 'Brokerage Revenue', 'Mileage'];

export default {
  list: mileageOrRevenueTypes.map((type) => ({
    type,
    projected: null,
    currentPolicyPeriod: null,
    firstPriorYear: null,
    secondPriorYear: null,
    thirdPriorYear: null,
    fourthPriorYear: null,
  })),
};
