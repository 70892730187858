export const defaultInsuranceHistoryAndLossExperienceObject = {
  company: null,
  policyTerm: null,
  numberOfPowerUnits: null,
  numberOfTrailers: null,
  premium: null,
  incurredClaimsAutoLiability: null,
  incurredClaimsPhysDmg: null,
  numberOfClaims: null,
};

export default {
  list: [defaultInsuranceHistoryAndLossExperienceObject],
  lossDescriptions: null,
};
