// @mui
import { styled } from '@mui/material/styles';
// uppy
import { Dashboard } from '@uppy/react';

// ----------------------------------------------------------------------

export const StyledDashboard = styled(Dashboard)({
  '.uppy-Dashboard': {
    '&-inner': {
      width: '100% !important',
      height: '505px !important',
    },
    '&-serviceMsg': {
      display: 'none',
    },
  },
});
