import { useEffect } from 'react';
// state-machine
// import { DevTool } from 'little-state-machine-devtools';
import { useStateMachine } from 'little-state-machine';
import { updateFormStateVersion } from './form/actions';

// ------------------------------------------------------------------

function StateMachineSetup() {
  const { actions } = useStateMachine({ updateFormStateVersion });

  useEffect(() => {
    actions.updateFormStateVersion();
  }, [actions]);

//   if (process.env.NODE_ENV === 'development') return <DevTool />;

  return null;
}

export default StateMachineSetup;
