import { useEffect, useState } from 'react';
// nav
import navConfig from '../layouts/dashboard/nav/config';

// ---------------------------------------------------------------

const useFormStepNavigation = (step) => {
  const [prevStep, setPrevStep] = useState(null);
  const [nextStep, setNextStep] = useState(null);

  useEffect(() => {
    const formPaths = navConfig.filter((item) => item.path.includes('/form/')); // filter for form paths
    const currentIndex = formPaths.findIndex((item) => item.path.includes(step)); // find index of current step

    const prevIndex = currentIndex - 1; // index of previous step
    const nextIndex = currentIndex + 1; // index of next step

    const prevStep = formPaths[prevIndex]; // previous step object
    const nextStep = formPaths[nextIndex]; // next step object or null if at end of array

    setPrevStep(prevStep);
    setNextStep(nextStep);
  }, [step]);

  return { prevStep, nextStep };
};

export default useFormStepNavigation;
