// mui
import { Divider, Grid, Typography } from '@mui/material';
// form
import { useForm } from 'react-hook-form';
// little-state-machine
import { useStateMachine } from 'little-state-machine';
// component
import { FieldCondition, FormProvider, RHFCheckbox, RHFTextField } from '../../../../components/hook-form';
import FormSectionControls from '../../FormSectionControls';
// hooks
import useFormStateUpdateOnChange from '../../../../hooks/useFormStateUpdateOnChange';

// -------------------------------------------------------

const Form = () => {
  // state
  const { state } = useStateMachine();

  // form
  const methods = useForm({
    mode: 'onChange',
    defaultValues: state.form,
  });

  const { watch } = methods;

  useFormStateUpdateOnChange(watch);

  return (
    <FormProvider methods={methods}>
      <Grid container spacing={2}>
        {/* Liability / General Liability */}
        <Grid item xs={12}>
          <Typography variant={'subtitle1'}>Liability / General Liability</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField
            type={'number'}
            name={'coveragesAndLimits.liabilityGeneralLiability.alLimit'}
            label={'AL Limit'}
            // autoFocus
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField
            type={'number'}
            name={'coveragesAndLimits.liabilityGeneralLiability.glLimit'}
            label={'GL Limit'}
          />
        </Grid>

        {/* Hired Auto */}
        <Grid item xs={12}>
          <RHFCheckbox name={'coveragesAndLimits.liabilityGeneralLiability.hiredAuto.hiredAuto'} label={'Hired Auto'} />
        </Grid>
        <FieldCondition fieldName="coveragesAndLimits.liabilityGeneralLiability.hiredAuto.hiredAuto">
          <Grid item xs={12} sm={6}>
            <RHFTextField
              type={'number'}
              name={'coveragesAndLimits.liabilityGeneralLiability.hiredAuto.costOfHire'}
              label={'Cost of Hire'}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <RHFCheckbox name={'coveragesAndLimits.liabilityGeneralLiability.hiredAuto.ifAny'} label={'If Any'} />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </FieldCondition>

        {/* Non Owned Auto */}
        <Grid item xs={12}>
          <RHFCheckbox
            name={'coveragesAndLimits.liabilityGeneralLiability.nonOwnedAuto.nonOwnedAuto'}
            label={'Non-Owned Auto'}
          />
        </Grid>
        <FieldCondition fieldName="coveragesAndLimits.liabilityGeneralLiability.nonOwnedAuto.nonOwnedAuto">
          <Grid item xs={12} sm={6}>
            <RHFTextField
              type={'number'}
              name={'coveragesAndLimits.liabilityGeneralLiability.nonOwnedAuto.numberOfEmployees'}
              label={'Number Of Employees'}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </FieldCondition>

        {/* Limits */}
        <Grid item xs={12} sm={6}>
          <RHFTextField
            type={'number'}
            name={'coveragesAndLimits.liabilityGeneralLiability.umLimit'}
            label={'UM Limit'}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField
            type={'number'}
            name={'coveragesAndLimits.liabilityGeneralLiability.uimLimit'}
            label={'UIM Limit'}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField
            type={'number'}
            name={'coveragesAndLimits.liabilityGeneralLiability.medicalPaymentLimit'}
            label={'Medical Payment Limit'}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField
            type={'number'}
            name={'coveragesAndLimits.liabilityGeneralLiability.pipLimit'}
            label={'PIP Limit'}
          />
        </Grid>

        {/* Physical Damage / Cargo */}
        <Grid item xs={12}>
          <Typography variant={'subtitle1'}>Physical Damage / Cargo</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField
            type={'number'}
            name={'coveragesAndLimits.physicalDamageCargo.comprehensiveAndCollisionDeductible'}
            label={'Comprehensive and Collision Deductible'}
          />
        </Grid>

        {/* Trailer Interchange */}
        <Grid item xs={12}>
          <Typography color={'GrayText'}>Trailer Interchange</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField
            type={'number'}
            name={'coveragesAndLimits.physicalDamageCargo.trailerInterchange.limitDeductible'}
            label={'Limit / Deductible'}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField
            type={'number'}
            name={'coveragesAndLimits.physicalDamageCargo.trailerInterchange.cargoLimitDeductible'}
            label={'Cargo Limit / Deductible'}
          />
        </Grid>

        {/* Non Owned Trailer Physical Damage */}
        <Grid item xs={12}>
          <Typography color={'GrayText'}>Non Owned Trailer Physical Damage</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField
            type={'number'}
            name={'coveragesAndLimits.physicalDamageCargo.nonOwnedTrailerPhysicalDamage.limitDeductible'}
            label={'Limit / Deductible'}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField
            type={'number'}
            name={'coveragesAndLimits.physicalDamageCargo.nonOwnedTrailerPhysicalDamage.numberOfTrailers'}
            label={'Number of Trailers'}
          />
        </Grid>
      </Grid>

      <FormSectionControls step={'coverges-and-limits'} />
    </FormProvider>
  );
};

export default Form;
