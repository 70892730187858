// mui
import { Grid } from '@mui/material';
// form
import { useForm } from 'react-hook-form';
// little-state-machine
import { useStateMachine } from 'little-state-machine';
// component
import { FormProvider } from '../../../../components/hook-form';
import FormSectionControls from '../../FormSectionControls';
import MileageAndRevenueList from './MileageAndRevenueList';
// hooks
import useFormStateUpdateOnChange from '../../../../hooks/useFormStateUpdateOnChange';

// -------------------------------------------------------

const Form = () => {
  // state
  const { state } = useStateMachine();

  // form
  const methods = useForm({
    mode: 'onChange',
    defaultValues: state.form,
  });

  const { watch } = methods;

  useFormStateUpdateOnChange(watch);

  return (
    <FormProvider methods={methods}>
      <Grid container spacing={2}>
        <MileageAndRevenueList />
      </Grid>

      <FormSectionControls step={'mileage-and-revenue'} />
    </FormProvider>
  );
};

export default Form;
