// mui
import { Grid, Typography } from '@mui/material';
// form
import { useForm } from 'react-hook-form';
// little-state-machine
import { useStateMachine } from 'little-state-machine';
// component
import {
  FieldCondition,
  FormProvider,
  RHFCheckbox,
  RHFDatePicker,
  RHFSelect,
  RHFTextField,
} from '../../../../components/hook-form';
import FormSectionControls from '../../FormSectionControls';
// hooks
import useFormStateUpdateOnChange from '../../../../hooks/useFormStateUpdateOnChange';

// -------------------------------------------------------

const Form = () => {
  // state
  const { state } = useStateMachine();

  // form
  const methods = useForm({
    mode: 'onChange',
    defaultValues: state.form,
  });

  const { watch } = methods;

  useFormStateUpdateOnChange(watch);

  return (
    <FormProvider methods={methods}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <RHFTextField
            name={'generalInformation.agency'}
            label={'Agency'}
            // autoFocus
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <RHFTextField name={'generalInformation.producer'} label={'Producer'} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <RHFTextField name={'generalInformation.email'} label={'Email'} required />
        </Grid>
        <Grid item>
          <RHFCheckbox name={'generalInformation.autoLiability'} label={'Auto Liability'} />
        </Grid>
        <Grid item>
          <RHFCheckbox name={'generalInformation.autoPhysicalDamage'} label={'Auto Physical Damage'} />
        </Grid>
        <Grid item>
          <RHFCheckbox name={'generalInformation.motorTruckCargo'} label={'Motor Truck Cargo'} />
        </Grid>
        <Grid item>
          <RHFCheckbox name={'generalInformation.truckersGeneralLiability'} label={'Truckers General Liability'} />
        </Grid>
        <Grid item>
          <RHFCheckbox name={'generalInformation.trailerInterchange'} label={'Trailer Interchange'} />
        </Grid>

        <Grid item xs={12}>
          <Typography variant={'h5'}>General Information</Typography>
        </Grid>

        <Grid item xs={12} sm={4}>
          <RHFDatePicker clearable name={'generalInformation.effectiveDate'} label={'Effective Date'} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <RHFTextField
            type={'number'}
            name={'generalInformation.yearsInTruckingIndustry'}
            label={'Years In Trucking Industry'}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <RHFTextField type={'number'} name={'generalInformation.yearsInBussiness'} label={'Years In Bussiness'} />
        </Grid>

        <Grid item xs={12} sm={4}>
          <RHFTextField name={'generalInformation.name'} label={'Name'} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <RHFTextField name={'generalInformation.scac'} label={'SCAC'} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <RHFTextField name={'generalInformation.dba'} label={'DBA'} />
        </Grid>

        <Grid item xs={12}>
          <RHFCheckbox
            name={'generalInformation.doesNamedInsuredIncludeDba'}
            label={'Does Named Insured Include DBA?'}
          />
        </Grid>

        <Grid item container xs={12} spacing={2}>
          <Grid item container xs={12} sm={6}>
            <RHFSelect
              name={'generalInformation.applicantType'}
              label={'Applicant Type'}
              options={['Individual', 'Corporation', 'Partnership', 'LLC', 'Other', 'None']}
            />
          </Grid>

          <FieldCondition fieldName="generalInformation.applicantType" condition={(val) => val === 'Other'}>
            <Grid item container xs={12} sm={6}>
              <RHFTextField name={'generalInformation.applicantTypeOther'} label={'Other Applicant Type'} />
            </Grid>
          </FieldCondition>
        </Grid>

        <Grid item xs={12} sm={4}>
          <RHFTextField name={'generalInformation.feinOrSs'} label={'FEIN Or SS #'} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <RHFTextField name={'generalInformation.usDot'} label={'US DOT #'} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <RHFTextField name={'generalInformation.iccMcMx'} label={'ICC # MC # or MX #'} />
        </Grid>

        <Grid item xs={12}>
          <RHFCheckbox name={'generalInformation.stateFilingsRequired'} label={'State Filings required?'} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField name={'generalInformation.state'} label={'State'} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField name={'generalInformation.permit'} label={'Permit'} />
        </Grid>

        <Grid item xs={12}>
          <Typography variant={'subtitle1'}>Mailing Address</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <RHFTextField name={'generalInformation.mailingAddress.address'} label={'Address'} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <RHFTextField name={'generalInformation.mailingAddress.city'} label={'City'} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <RHFTextField name={'generalInformation.mailingAddress.state'} label={'State'} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <RHFTextField name={'generalInformation.mailingAddress.zip'} label={'Zip'} />
        </Grid>

        <Grid item xs={12}>
          <Typography variant={'subtitle1'}>Garaging Address</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <RHFTextField name={'generalInformation.garagingAddress.address'} label={'Address'} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <RHFTextField name={'generalInformation.garagingAddress.city'} label={'City'} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <RHFTextField name={'generalInformation.garagingAddress.state'} label={'State'} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <RHFTextField name={'generalInformation.garagingAddress.zip'} label={'Zip'} />
        </Grid>

        <Grid item xs={12}>
          <RHFCheckbox
            name={'generalInformation.isGaragingLocationResidential'}
            label={'Is the garaging location Residential?'}
          />
        </Grid>

        <FieldCondition fieldName="generalInformation.isGaragingLocationResidential">
          <Grid item xs={12}>
            <RHFTextField
              name={'generalInformation.residentialGarageDescription'}
              label={'Please describe where vehicle is parked and security measures'}
              multiline
              minRows={5}
            />
          </Grid>
        </FieldCondition>

        <Grid item xs={12} sm={6} md={3}>
          <RHFTextField name={'generalInformation.contactPersonName'} label={'Contact Person Name'} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <RHFTextField name={'generalInformation.emailAddress'} label={'Email Address'} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <RHFTextField name={'generalInformation.businessPhone'} label={'Business Phone'} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <RHFTextField name={'generalInformation.mobilePhone'} label={'Mobile Phone'} />
        </Grid>
      </Grid>

      <FormSectionControls step={'general-information'} />
    </FormProvider>
  );
};

export default Form;
