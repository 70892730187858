import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { MenuItem, TextField } from '@mui/material';

// ----------------------------------------------------------------------

RHFSelect.propTypes = {
  name: PropTypes.string,
  readOnly: PropTypes.bool,
  options: PropTypes.array,
};

export default function RHFSelect({ name, options, readOnly, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          select
          fullWidth
          error={!!error}
          value={field.value !== null && field.value}
          helperText={error?.message}
          inputProps={{ readOnly }}
          {...other}
        >
          {options.map((item) => {
            const value = typeof item === 'string' ? item : item.value;
            const label = typeof item === 'string' ? item : item.label;

            return (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            );
          })}
        </TextField>
      )}
    />
  );
}
