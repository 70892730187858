export default {
  anticipatedUnitsAdditionDuringPolicyTerm: null,
  numberOfUnitsToAdd: null,
  numberOwnedUnitsByNamedInsured: null,
  numberOfOwnerOperatorUnits: null,
  hireOtherCarriersOrOwnerOperators: null,
  hireOtherCarriersOrOwnerOperatorsScheduled: null,
  hazardousMaterialHauling: null,
  gpsEldDashCamMonitoringEquipment: null,
  gpsEldDashCamMonitoringEquipmentDescription: null,
  pullDoubleTrailers: null,
  pullTripleTrailers: null,
  oversizeOrOverweightLoads: null,
  tightDeliveryTimeConstraints: null,
  isEquipmentScheduledOnApplication: null,
  isInsuredInvolvedInOtherBusinessActivity: null,
  actAsFreightBrokerOrForwarderOrLoadArranger: null,
  everFiledBankruptcy: null,
  declinedCancelledNonRenewedLast3Years: null,
  truckerUniformIntermodalEndorsementRequired: null,
  carryWorkersCompensation: null,
  isAnyOperationSeasonal: null,
  leaseToOthers: null,
  primaryLiabilityCoverageProvider: null,
  formalSafetyProgram: null,
  vehicleMaintenanceProgramInPlace: null,
  personalVehicleUse: null,
  equipmentScheduledOnApplication: null,
  anticipateTravelOutsideUS: null,
  forwardFacingCameras: null,

  additionalExplanation: null,
};
