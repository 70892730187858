// mui
import { Grid, Typography } from '@mui/material';
// form
import { useForm } from 'react-hook-form';
// little-state-machine
import { useStateMachine } from 'little-state-machine';
// component
import { FormProvider, RHFTextField } from '../../../../components/hook-form';
import FormSectionControls from '../../FormSectionControls';
import InsuranceHistoryAndLossExperienceList from './InsuranceHistoryAndLossExperienceList';
// hooks
import useFormStateUpdateOnChange from '../../../../hooks/useFormStateUpdateOnChange';

// -------------------------------------------------------

const Form = () => {
  // state
  const { state } = useStateMachine();

  // form
  const methods = useForm({
    mode: 'onChange',
    defaultValues: state.form,
  });

  const { watch } = methods;

  useFormStateUpdateOnChange(watch);

  return (
    <FormProvider methods={methods}>
      <Grid container spacing={2}>
        <InsuranceHistoryAndLossExperienceList />

        <Grid item xs={12}>
          <Typography variant={'subtitle1'} color={'GrayText'}>
            <b>NOTE:</b> Please include if there was any lapse in coverage period
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <RHFTextField
            name={'insuranceHistoryAndLossExperience.lossDescriptions'}
            label={'Loss Descriptions'}
            multiline
            minRows={5}
          />
        </Grid>
      </Grid>

      <FormSectionControls step={'insurance-history-and-loss-experience'} />
    </FormProvider>
  );
};

export default Form;
