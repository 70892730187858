import PropTypes from 'prop-types';
// @mui
import { Card, CardContent, IconButton, Stack, Typography } from '@mui/material';
// components
import Iconify from '../iconify/Iconify';

// -------------------------------------------------------------------------

const CardSection = ({ title, onRemove, isRemoveDisabled, children }) => (
  <Stack spacing={1}>
    <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={'space-between'}>
      {Boolean(title) && (
        <Typography textTransform={'uppercase'} variant="body1" color={'text.secondary'}>
          {title}
        </Typography>
      )}
      {onRemove && (
        <IconButton disabled={isRemoveDisabled} color={'error'} onClick={onRemove} edge="end">
          <Iconify icon={'carbon:trash-can'} width={25} height={25} />
        </IconButton>
      )}
    </Stack>
    <Card>
      <CardContent>
        <Stack spacing={2}>{children}</Stack>
      </CardContent>
    </Card>
  </Stack>
);

CardSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  onRemove: PropTypes.func,
  isRemoveDisabled: PropTypes.bool,
};

export default CardSection;
