// @mui
import { Container, Stack, Typography } from '@mui/material';
// components
import Page from '../../components/page';
import Form from '../../sections/form/descriptionOfOperations/form';

// ----------------------------------------------------------------------

const DescriptionOfOperations = () => (
  <Page title={'Description Of Operations'}>
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
        <Typography variant="h4" gutterBottom>
          Description Of Operations
        </Typography>
      </Stack>

      <Form />
    </Container>
  </Page>
);

export default DescriptionOfOperations;
