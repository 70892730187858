export default {
  common: [
    { name: 'autoHauler', label: 'Auto Hauler', value: false },
    { name: 'customized', label: 'Customized', value: false },
    { name: 'dryBulk', label: 'Dry Bulk', value: false },
    { name: 'dryVan', label: 'Dry Van', value: false },
    { name: 'dumpBottom', label: 'Dump - Bottom', value: false },
    { name: 'dumpEnd', label: 'Dump - End', value: false },
    { name: 'dumpSide', label: 'Dump - Side', value: false },
    { name: 'flatbed', label: 'Flatbed', value: false },
    { name: 'lowBoy', label: 'Low Boy', value: false },
    { name: 'pneumatic', label: 'Pneumatic', value: false },
    { name: 'refrigerated', label: 'Refrigerated', value: false },
    { name: 'tanker', label: 'Tanker', value: false },
  ],
  // other is array of objects with field name and value
  other: [],
};
