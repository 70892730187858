// @mui
import { Container, Stack, Typography } from '@mui/material';
// components
import Page from '../../components/page';
import Form from '../../sections/form/driverInformation/form';

// ----------------------------------------------------------------------

const DriverInformation = () => (
  <Page title={'Driver Information'}>
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
        <Typography variant="h4" gutterBottom>
          Driver Information
        </Typography>
      </Stack>

      <Form />
    </Container>
  </Page>
);

export default DriverInformation;
