import PropTypes from 'prop-types';
// @mui
import { Grid } from '@mui/material';
// components
import { RHFTextField } from '../../../../../components/hook-form';
import CardSection from '../../../../../components/card-section';

//-------------------------------------------------------------------------------------------------

const CommodityPercentageFields = ({ index, onRemove, isRemoveDisabled }) => (
  <CardSection title={`Commodity #${index + 1}`} onRemove={() => onRemove(index)} isRemoveDisabled={isRemoveDisabled}>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <RHFTextField name={`commoditiesOperations.commodityPercentages[${index}].commodity`} label={'Commodity'} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <RHFTextField
          type={'number'}
          name={`commoditiesOperations.commodityPercentages[${index}].percentage`}
          label={'Percentage'}
        />
      </Grid>
    </Grid>
  </CardSection>
);

CommodityPercentageFields.propTypes = {
  onRemove: PropTypes.func,
  isRemoveDisabled: PropTypes.bool,
  index: PropTypes.number,
};

export default CommodityPercentageFields;
