export const defaultDriverInformationObject = {
  name: null,
  dateOfBirth: null,
  license: null,
  state: null,
  yrsOfCDLExperience: null,
  yrsWithCompany: null,
  typeOfEmployee: 'None',
};

export default [defaultDriverInformationObject];
