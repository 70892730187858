// component
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'General Information',
    path: '/form/general-information',
  },
  {
    title: 'Coverges and limits',
    path: '/form/coverges-and-limits',
  },
  {
    title: 'Description Of Operations',
    path: '/form/description-of-operations',
  },
  {
    title: 'Commodities / Operations',
    path: '/form/commodities-operations',
  },
  {
    title: 'Schedule Of Units',
    path: '/form/schedule-of-units',
  },
  {
    title: 'Trailer Types',
    path: '/form/trailer-types',
  },
  {
    title: 'Driver Information',
    path: '/form/driver-information',
  },
  {
    title: 'Insurance History & Loss Experience',
    path: '/form/insurance-history-and-loss-experience',
  },
  {
    title: 'Mileage & Revenue',
    path: '/form/mileage-and-revenue',
  },
  {
    title: 'General Liability',
    path: '/form/general-liability',
  },
  {
    title: 'Policy Information Questions',
    path: '/form/policy-information-questions',
  },
  {
    title: 'Attachments',
    path: '/form/attachments',
    icon: getIcon('ion:document-attach-outline'),
  },
  {
    title: 'Policy Ownership And Fraud Statement',
    path: '/form/policy-ownership-and-fraud-statement',
    icon: getIcon('ion:document-text-outline'),
  },
];

export default navConfig;
