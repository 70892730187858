import PropTypes from 'prop-types';
// @mui
import { Grid } from '@mui/material';
// components
import { RHFTextField } from '../../../../../components/hook-form';
import CardSection from '../../../../../components/card-section';

//-------------------------------------------------------------------------------------------------

const WaiverOfSubrogationOrAdditionalInsuredFields = ({ onRemove, isRemoveDisabled, index }) => (
  <CardSection
    title={`Additional Insured / Waiver of Subrogation #${index + 1}`}
    onRemove={() => onRemove(index)}
    isRemoveDisabled={isRemoveDisabled}
  >
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={6}>
        <RHFTextField name={`generalLiability.waiverOfSubrogationOrAdditionalInsured.${index}.name`} label={'Name'} />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <RHFTextField
          name={`generalLiability.waiverOfSubrogationOrAdditionalInsured.${index}.address`}
          label={'Address'}
        />
      </Grid>
    </Grid>
  </CardSection>
);

WaiverOfSubrogationOrAdditionalInsuredFields.propTypes = {
  onRemove: PropTypes.func,
  isRemoveDisabled: PropTypes.bool,
  index: PropTypes.number,
};

export default WaiverOfSubrogationOrAdditionalInsuredFields;
