import PropTypes from 'prop-types';
// @mui
import { Grid } from '@mui/material';
// components
import { RHFSelect, RHFTextField } from '../../../../../components/hook-form';
import CardSection from '../../../../../components/card-section';

//-------------------------------------------------------------------------------------------------

const ScheduleOfUnitsFields = ({ index, onRemove, isRemoveDisabled }) => (
  <CardSection title={`Unit #${index + 1}`} onRemove={() => onRemove(index)} isRemoveDisabled={isRemoveDisabled}>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <RHFTextField name={`scheduleOfUnits[${index}].year`} label={'Year'} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <RHFTextField name={`scheduleOfUnits[${index}].make`} label={'Make'} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <RHFTextField name={`scheduleOfUnits[${index}].type`} label={'Type'} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <RHFTextField
          name={`scheduleOfUnits[${index}].vin`}
          label={'VIN Number'}
          inputProps={{ style: { textTransform: 'uppercase' } }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <RHFTextField name={`scheduleOfUnits[${index}].gvw`} label={'GVW'} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <RHFTextField type={'number'} name={`scheduleOfUnits[${index}].statedValue`} label={'Stated Value'} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <RHFSelect
          name={`scheduleOfUnits[${index}].ownershipStatus`}
          label={'Ownership Status'}
          options={['Owned', 'Leased', 'None']}
        />
      </Grid>
    </Grid>
  </CardSection>
);

ScheduleOfUnitsFields.propTypes = {
  onRemove: PropTypes.func,
  isRemoveDisabled: PropTypes.bool,
  index: PropTypes.number,
};

export default ScheduleOfUnitsFields;
