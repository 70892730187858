export const defaultScheduleOfUnitsObject = {
  year: null,
  make: null,
  type: null,
  vin: null,
  gvw: null,
  statedValue: null,
  ownershipStatus: 'None',
};

export default [defaultScheduleOfUnitsObject];
