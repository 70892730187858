import PropTypes from 'prop-types';
// mui
import { Card, CardContent } from '@mui/material';

// ------------------------------------------------------------

const Banner = ({ type, children, sx, ...rest }) => (
  <Card
    sx={{
      borderRadius: '3px',
      bgcolor: (theme) => theme.palette[type].lighter,
      borderLeft: '4px solid',
      borderLeftColor: (theme) => theme.palette[type].light,
      ...sx,
    }}
    {...rest}
  >
    <CardContent>{children}</CardContent>
  </Card>
);

Banner.propTypes = {
  type: PropTypes.oneOf(['info', 'success', 'error', 'warning']).isRequired,
  children: PropTypes.node.isRequired,
  sx: PropTypes.object,
};

export default Banner;
