// @mui
import { Grid } from '@mui/material';
// form
import { useFieldArray, useFormContext } from 'react-hook-form';
// state
import { useStateMachine } from 'little-state-machine';
// components
import { RHFCheckbox } from '../../../../../components/hook-form';

//-------------------------------------------------------------------------------------------------

const CommonTypesList = () => {
  // state
  const { state } = useStateMachine();

  // form
  const { control } = useFormContext();

  const { fields } = useFieldArray({
    control,
    name: 'trailerTypes.common',
    defaultValues: state.form.trailerTypes.common,
  });

  return (
    <Grid item container xs={12}>
      {fields.map(({ label, id }, idx) => (
        <Grid item key={id} xs={6} sm={4} md={3} xl={2}>
          <RHFCheckbox name={`trailerTypes.common.${idx}.value`} label={label} />
        </Grid>
      ))}
    </Grid>
  );
};

export default CommonTypesList;
