// mui
import { Button, Grid, Stack, Typography } from '@mui/material';
// form
import { useFieldArray, useForm } from 'react-hook-form';
// little-state-machine
import { useStateMachine } from 'little-state-machine';
import { defaultOperationStatesDataObject } from '../../../../store/form/state/descriptionOfOperations';
// component
import { FieldCondition, FormProvider, RHFSelect, RHFTextField } from '../../../../components/hook-form';
import FormSectionControls from '../../FormSectionControls';
import OperationStateDataFields from './fields/OperationStateDataFields';
import Iconify from '../../../../components/iconify/Iconify';
// hooks
import useFormStateUpdateOnChange from '../../../../hooks/useFormStateUpdateOnChange';

// -------------------------------------------------------

const Form = () => {
  // state
  const { state } = useStateMachine();

  // form
  const methods = useForm({
    mode: 'onChange',
    defaultValues: state.form,
  });

  const { watch, control } = methods;

  useFormStateUpdateOnChange(watch);

  const {
    fields: operationStatesData,
    append: appendOperationStateData,
    remove: removeOperationStateData,
  } = useFieldArray({
    control,
    name: 'descriptionOfOperations.operationStatesData',
    defaultValues: state.form.descriptionOfOperations.operationStatesData,
  });

  const handleAppendOperationStateData = () => appendOperationStateData(defaultOperationStatesDataObject);
  const handleRemoveOperationStateData = (index) =>
    operationStatesData?.length > 1 ? removeOperationStateData(index) : null;

  return (
    <FormProvider methods={methods}>
      <Grid container spacing={2}>
        <Grid item container xs={12} spacing={2}>
          <Grid item container xs={12} sm={6}>
            <RHFSelect
              name={'descriptionOfOperations.typeOfOperation'}
              label={'Type Of Operation'}
              options={['For Hire', 'Private', 'Non-Trucking', 'Other', 'None']}
              // autoFocus
            />
          </Grid>

          <FieldCondition fieldName="descriptionOfOperations.typeOfOperation" condition={(val) => val === 'Other'}>
            <Grid item container xs={12} sm={6}>
              <RHFTextField name={'descriptionOfOperations.typeOfOperationOther'} label={'Other Type Of Operation'} />
            </Grid>
          </FieldCondition>

          <Grid item container xs={12} sm={6}>
            <RHFSelect
              name={'descriptionOfOperations.rangeOfTransport'}
              label={'Range of Transport'}
              options={['Interstate', 'Intrastate', 'None']}
            />
          </Grid>

          {/* Percent Of Loads By Radius */}
          <Grid item xs={12}>
            <Typography variant={'subtitle1'}>Percent Of Loads By Radius</Typography>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <RHFTextField
              type={'number'}
              name={'descriptionOfOperations.percentOfLoadsByRadius.within250mi'}
              label={'0 – 250 mi'}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <RHFTextField
              type={'number'}
              name={'descriptionOfOperations.percentOfLoadsByRadius.within251to750mi'}
              label={'251 – 750 mi'}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <RHFTextField
              type={'number'}
              name={'descriptionOfOperations.percentOfLoadsByRadius.within751to1500mi'}
              label={'751 – 1500 mi'}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <RHFTextField
              type={'number'}
              name={'descriptionOfOperations.percentOfLoadsByRadius.over1500mi'}
              label={'1501+ mi'}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <RHFTextField type={'number'} name={'descriptionOfOperations.averageRadius'} label={'Average Radius'} />
          </Grid>

          {/* Operation States Data */}
          <Grid item xs={12}>
            <Typography variant={'subtitle1'} color={'GrayText'}>
              Please List Operation States and Percentage of Miles in Below
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={2}>
              {operationStatesData?.map(({ id }, idx) => (
                <OperationStateDataFields
                  onRemove={handleRemoveOperationStateData}
                  isRemoveDisabled={operationStatesData?.length === 1}
                  index={idx}
                  key={id}
                />
              ))}
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack alignItems={'flex-end'} direction={'column'}>
              <Button
                variant={'text'}
                onClick={handleAppendOperationStateData}
                endIcon={<Iconify icon="eva:plus-fill" width={25} height={25} />}
              >
                Add Operation State Data
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Grid>

      <FormSectionControls step={'description-of-operations'} />
    </FormProvider>
  );
};

export default Form;
