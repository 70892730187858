import PropTypes from 'prop-types';
// form
import { useFormContext, useWatch } from 'react-hook-form';
// lodash
import _ from 'lodash';
// state
import state from '../../store';

// -----------------------------------------------------------------

const FieldCondition = ({ condition, fieldName, children }) => {
  const { control } = useFormContext();

  const value = useWatch({ control, name: fieldName, defaultValue: _.get(state, fieldName) });

  const matchCondition = condition?.(value) || false;

  // Render the children if the condition is met.
  // If the condition is not provided, render the children if the value is truthy.
  if ((!condition && (value === true || value === 'true')) || matchCondition) {
    return children;
  }

  return null;
};

FieldCondition.propTypes = {
  condition: PropTypes.func,
  children: PropTypes.node.isRequired,
  fieldName: PropTypes.string.isRequired,
};

export default FieldCondition;
