// @mui
import { Container, Stack, Typography } from '@mui/material';
// components
import Page from '../../components/page';
import Form from '../../sections/form/policyOwnershipAndFraudStatement/form';

// ----------------------------------------------------------------------

const PolicyOwnershipAndFraudStatement = () => (
  <Page title={'Policy Ownership and Fraud Statement'}>
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
        <Typography variant="h4" gutterBottom>
          Policy Ownership and Fraud Statement
        </Typography>
      </Stack>

      <Form />
    </Container>
  </Page>
);

export default PolicyOwnershipAndFraudStatement;
