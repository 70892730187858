import PropTypes from 'prop-types';
// @mui
import { Grid } from '@mui/material';
// components
import { RHFCheckbox, RHFSelect, RHFTextField } from '../../../../../components/hook-form';
import CardSection from '../../../../../components/card-section';

//-------------------------------------------------------------------------------------------------

const FacilityFields = ({ onRemove, isRemoveDisabled, index }) => (
  <CardSection title={`Facility #${index + 1}`} onRemove={() => onRemove(index)} isRemoveDisabled={isRemoveDisabled}>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={6}>
        <RHFSelect
          name={`generalLiability.facilities.${index}.occupancyType`}
          label={'Occupancy Type'}
          options={[
            'Apartment House',
            'Contractors',
            'Industrial and Processing',
            'Institutional',
            'Mercantile',
            'Motel/Hotel',
            'Office',
            'Service',
            'None',
          ]}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <RHFTextField name={`generalLiability.facilities.${index}.address`} label={'Address'} />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <RHFTextField type={'number'} name={`generalLiability.facilities.${index}.numberOfUnits`} label={'# Units'} />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <RHFTextField
          type={'number'}
          name={`generalLiability.facilities.${index}.numberOfEmployees`}
          label={'# Employees'}
        />
      </Grid>
      <Grid item>
        <RHFCheckbox name={`generalLiability.facilities.${index}.isFenced`} label={'Fenced'} />
      </Grid>
      <Grid item>
        <RHFCheckbox name={`generalLiability.facilities.${index}.isLighted`} label={'Lighted'} />
      </Grid>
      <Grid item>
        <RHFCheckbox name={`generalLiability.facilities.${index}.hasSecurityGuard`} label={'Security Guard'} />
      </Grid>
      <Grid item>
        <RHFCheckbox name={`generalLiability.facilities.${index}.isPublicAccessAllowed`} label={'Public Access'} />
      </Grid>
      <Grid item>
        <RHFCheckbox name={`generalLiability.facilities.${index}.hasSecurityCamera`} label={'Security Camera'} />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <RHFTextField
          name={`generalLiability.facilities.${index}.nonTruckingPayroll`}
          label={'**Non Trucking Payroll'}
        />
      </Grid>
    </Grid>
  </CardSection>
);

FacilityFields.propTypes = {
  onRemove: PropTypes.func,
  isRemoveDisabled: PropTypes.bool,
  index: PropTypes.number,
};

export default FacilityFields;
