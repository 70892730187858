import { useState } from 'react';
import PropTypes from 'prop-types';
// router
import { useNavigate } from 'react-router-dom';
// mui
import { Button, Divider, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// lodash
import _ from 'lodash';
// state
import { useStateMachine } from 'little-state-machine';
// notistack
import { enqueueSnackbar } from 'notistack';
// components
import Iconify from '../../components/iconify/Iconify';
// hooks
import useFormStepNavigation from '../../hooks/useFormStepNavigation';
import useUppy from '../../hooks/useUppy';
// api
import formApi from '../../api/formApi';

// ----------------------------------------------------------------

const FormSectionControls = ({ step, hideDivider, validate }) => {
  // navigate
  const navigate = useNavigate();

  // state
  const { prevStep, nextStep } = useFormStepNavigation(step);

  const { title: prevStepTitle, path: prevStepPath } = prevStep || {};
  const { title: nextStepTitle, path: nextStepPath } = nextStep || {};

  // files
  const { files } = useUppy();

  // validation
  const getIsValid = async () => {
    let isValid = true;

    if (_.isFunction(validate)) {
      isValid = await validate();
    }

    return isValid;
  };

  // prev click handler
  const handlePrevClick = () => {
    if (prevStepPath) navigate(prevStepPath);
  };

  // next click handler
  const handleNextClick = async () => {
    const isValid = await getIsValid();
    if (nextStepPath && isValid) navigate(nextStepPath);
  };

  // form submission
  const { state } = useStateMachine();

  const [isLoading, setIsLoading] = useState();

  const onSubmit = async () => {
    const isValid = await getIsValid();

    if (isValid) {
      setIsLoading(true);

      try {
        await formApi.upload({ data: state.form, files });
      } catch (error) {
        enqueueSnackbar(`Upload error: ${error}`, {
          variant: 'error',
        });
      }

      setIsLoading(false);
    }
  };

  return (
    <Stack spacing={2} mt={2}>
      {!hideDivider && <Divider />}

      <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={'flex-end'}>
        {prevStepPath && (
          <Button
            disabled={isLoading}
            onClick={handlePrevClick}
            variant={'text'}
            startIcon={<Iconify icon={'ion:arrow-back'} />}
          >
            {prevStepTitle}
          </Button>
        )}
        {nextStepPath && (
          <Button
            disabled={isLoading}
            onClick={handleNextClick}
            variant={'contained'}
            endIcon={<Iconify icon={'ion:arrow-forward'} />}
          >
            {nextStepTitle}
          </Button>
        )}
        {!nextStepPath && (
          <LoadingButton
            loading={isLoading}
            onClick={onSubmit}
            variant={'contained'}
            endIcon={<Iconify icon={'carbon:send-alt'} />}
          >
            Submit
          </LoadingButton>
        )}
      </Stack>
    </Stack>
  );
};

FormSectionControls.propTypes = {
  step: PropTypes.string.isRequired,
  hideDivider: PropTypes.bool,
  validate: PropTypes.func,
};

export default FormSectionControls;
