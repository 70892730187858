// @mui
import { Button, Grid, Stack } from '@mui/material';
// form
import { useFieldArray, useFormContext } from 'react-hook-form';
// state
import { useStateMachine } from 'little-state-machine';
import { defaultInsuranceHistoryAndLossExperienceObject } from '../../../../../store/form/state/insuranceHistoryAndLossExperience';
// components
import InsuranceHistoryAndLossExperienceFields from './InsuranceHistoryAndLossExperienceFields';
import Iconify from '../../../../../components/iconify/Iconify';

//-------------------------------------------------------------------------------------------------

const InsuranceHistoryAndLossExperienceList = () => {
  // state
  const { state } = useStateMachine();

  // form
  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'insuranceHistoryAndLossExperience.list',
    defaultValues: state.form.insuranceHistoryAndLossExperience.list,
  });

  const isRemoveDisabled = fields?.length < 2;

  const onAdd = () => append(defaultInsuranceHistoryAndLossExperienceObject);
  const onRemove = (index) => (isRemoveDisabled ? null : remove(index));

  return (
    <>
      <Grid item xs={12}>
        <Stack spacing={2}>
          {fields?.map(({ id }, idx) => (
            <InsuranceHistoryAndLossExperienceFields
              onRemove={onRemove}
              isRemoveDisabled={isRemoveDisabled}
              index={idx}
              key={id}
            />
          ))}
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack alignItems={'flex-end'} direction={'column'}>
          <Button variant={'text'} onClick={onAdd} endIcon={<Iconify icon="eva:plus-fill" width={25} height={25} />}>
            Add Insurance History & Loss Experience
          </Button>
        </Stack>
      </Grid>
    </>
  );
};

export default InsuranceHistoryAndLossExperienceList;
