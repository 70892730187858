export default {
  // HEADER
  agency: null,
  producer: null,
  email: null,
  autoLiability: false,
  autoPhysicalDamage: false,
  motorTruckCargo: false,
  truckersGeneralLiability: false,
  trailerInterchange: false,

  // GENERAL INFORMATION
  effectiveDate: null,
  yearsInTruckingIndustry: null,
  yearsInBussiness: null,
  name: null,
  scac: null,
  dba: null,
  doesNamedInsuredIncludeDba: false,
  applicantType: 'None',
  applicantTypeOther: null,
  feinOrSs: null,
  usDot: null,
  iccMcMx: null,
  stateFilingsRequired: false,
  state: null,
  permit: null,
  mailingAddress: {
    address: null,
    city: null,
    state: null,
    zip: null,
  },
  garagingAddress: {
    address: null,
    city: null,
    state: null,
    zip: null,
  },
  isGaragingLocationResidential: false,
  residentialGarageDescription: null,
  contactPersonName: null,
  emailAddress: null,
  businessPhone: null,
  mobilePhone: null,
};
